import DummyLogo from "@/assets/images/lucidity-placeholder.png";
import startupBenefits from "@/assets/json/startup-benefits.json";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import availablePricesDev from "@/hooks/availablePrices.dev";
import availablePricesMonthlyDev from "@/hooks/availablePrices.monthly.dev";
import availablePricesMonthlyProd from "@/hooks/availablePrices.monthly.prod";
import availablePricesProd from "@/hooks/availablePrices.prod";
import { ArrowFatLineUp, Check, CheckCircle, X } from "@phosphor-icons/react";

import AppContext from "@/AppContext";
import allAccessIcon from "@/assets/images/all_access_icon.png";
import {
  hidePurchasePopup,
  purchaseStartupPopup,
  registerPopup,
  startupToClaimAtom,
  userAtom,
} from "@/Atoms";
import useAuth from "@/hooks/auth";
import { sendToken, verifyTokenNotUser } from "@/services/api/Master";
import {
  checkStartupExist,
  createStartupByUser,
  updateStartup,
} from "@/services/api/Startup";
import { getStripePrice } from "@/services/api/Stripe";
import { createAbandonedCart } from "@/services/api/User";
import {
  checkEmailDomainWithList,
  getBaseDomainWithList,
  isValidDomain,
  isValidEmail,
} from "@/utils/checkEmailDomain";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useAtom } from "jotai";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import { useContext, useEffect, useState, useCallback } from "react";
import toast from "react-hot-toast";
import ErrorMessage from "../ErrorMessage";
import CardForm from "../stripe/CardForm";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { ScrollArea } from "../ui/scroll-area";
import InputTokenForAccountRegister from "./InputTokenForAccountRegister";
import StartupCheckout from "./StartupCheckout";
import StartupOwner from "./StartupOwner";

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
);

const steps = [
  { id: "Step 1", name: "Startup Information", href: "#", value: 1 },
  { id: "Step 2", name: "Payment Details", href: "#", value: 2 },
];

const PurchaseStartup = () => {
  const [openPurchasePopup, setOpenPurchasePopup] =
    useAtom(purchaseStartupPopup);
  const [isHidePurchasePopup, setIsHidePurchasePopup] =
    useAtom(hidePurchasePopup);
  const [openRegisterPopup, setOpenRegisterPopup] = useAtom(registerPopup);
  const [startupToClaim, setStartupToClaim] = useAtom(startupToClaimAtom);

  const router = useRouter();
  const { type } = router.query;
  const context = useContext(AppContext);
  const { planSubscription, loading, refreshUserSubscription } = context.state;
  const [playing, setIsPlaying] = useState(false);
  const [state, setState] = useState(null);
  const [startupName, setStartupName] = useState("");
  const [startupWebsite, setStartupWebsite] = useState("");
  const [error, setError] = useState({});
  const [startupExist, setStartupExist] = useState(false);
  const [isSameUserEmailAndWebsite, setIsSameUserEmailAndWebsite] = useState(false);
  const [foundStartup, setFoundStartup] = useState(null);
  const [claimedStartup, setClaimedStartup] = useState(null);
  const [selectedStep, setSelectedStep] = useState(1);
  const { user } = useAuth();
  const [isOpenStartupOwner, setIsOpenStartupOwner] = useState(false);
  const [selectedStartupOwner, setSelectedStartupOwner] = useState(null);
  const [officialEmail, setOfficialEmail] = useState("");
  const [confirmedOfficialEmail, setConfirmedOfficialEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isSendingVerifyEmail, setIsSendingVerifyEmail] = useState(false);
  const [emailVerificationLoading, setEmailVerificationLoading] =
    useState(false);
  const [emailConfirmLoading, setEmailConfirmLoading] = useState(false);
  const [confirmToken, setConfirmToken] = useState("");
  const [needToPutOfficialEmail, setNeedToPutOfficialEmail] = useState(false);
  const [userState, setUserState] = useAtom(userAtom);
  const [isOpen, setIsOpen] = useState(false);
  const [sendEmailVerificationLoading, setSendEmailVerificationLoading] =
    useState(false);
  const [placeholderEmails, setPlaceholderEmails] = useState('');

  const [isEmailValid, setIsEmailValid] = useState(false);

  const validateEmail = useCallback(async () => {
    if (officialEmail && isValidEmail(officialEmail)) {
      const isValid = await checkEmailDomainWithList(
        officialEmail,
        claimedStartup ? claimedStartup.website : startupWebsite
      );
      setIsEmailValid(isValid);
    } else {
      setIsEmailValid(false);
    }
  }, [officialEmail, claimedStartup, startupWebsite]);

  useEffect(() => {
    validateEmail();
  }, [validateEmail]);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: process.env.STRIPE_SECRET_KEY,
  };

  const onVerifyTokenUserRegister = async (e) => {
    e.preventDefault();
    if (!confirmToken) toast.error("Token is required!");
    setEmailVerificationLoading(true);
    setEmailConfirmLoading(true);
    try {
      const response = await verifyTokenNotUser({
        token: confirmToken,
      });
      try {
        if (planSubscription?.is_startup_plan) {
          if (claimedStartup) {
            await handleClaimStartup(claimedStartup.id);
          } else {
            await handleRegisterStartup();
          }
        } else {
          // setSelectedStep(2);
          onUpgradeStartup();
        }
        setConfirmedOfficialEmail(true);
        setIsSendingVerifyEmail(false);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      toast.error("Code is invalid or expired.");
    }
    setEmailVerificationLoading(false);
    setEmailConfirmLoading(false);
  };

  const onSendToken = async () => {
    if (!officialEmail) toast.error("Official Email is required!");
    // const freeEmails = ['yahoo.com', 'gmail.com', 'outlook.com', 'aol.com']

    // if (freeEmails.includes(officialEmail.split("@")[1])) {
    //   return toast.error('Email must be an official email!')
    // }

    setEmailVerificationLoading(true);
    try {
      const response = await sendToken({ email: officialEmail });
      toast.success(
        `Verification token just sent to ${officialEmail}, use that token to verify the email.`
      );
      setIsSendingVerifyEmail(true);
    } catch (error) {}
    setEmailVerificationLoading(false);
  };

  const onResendToken = async () => {
    if (!officialEmail) toast.error("Official Email is required!");
    // const freeEmails = ['yahoo.com', 'gmail.com', 'outlook.com', 'aol.com']

    // if (freeEmails.includes(officialEmail.split("@")[1])) {
    //   return toast.error('Email must be an official email!')
    // }

    setSendEmailVerificationLoading(true);
    try {
      const response = await sendToken({ email: officialEmail });
      toast.success(
        `Verification token just sent to ${officialEmail}, use that token to verify the email.`
      );
      setIsSendingVerifyEmail(true);
    } catch (error) {}
    setSendEmailVerificationLoading(false);
  };

  const handleUserIpAddress = async () => {
    let response;

    try {
      response = await axios.get(
        "https://api.bigdatacloud.net/data/ip-geolocation",
        {
          params: {
            key: "bdc_e7257c8213814ec68c8bf6708ad4431d",
          },
        }
      );
      console.log(response);
    } catch (error) {}

    const availablePrices =
      process.env.NEXT_PUBLIC_ENV !== "production"
        ? availablePricesDev
        : availablePricesProd;

    const availablePricesMonthly =
      process.env.NEXT_PUBLIC_ENV !== "production"
        ? availablePricesMonthlyDev
        : availablePricesMonthlyProd;

    let priceData = {};
    let priceDataMonthly = {};
    let currencyCode = "USD";
    let priceIds = availablePrices["USD"];
    let priceIdsMonthly = availablePricesMonthly["USD"];
    const codes = ["AED", "IDR", "JOD", "EGP", "SAR"];

    if (response && response.data?.country) {
      const currency = response.data.country.currency;
      if (currency && currency.code) {
        if (codes.includes(currency.code)) {
          currencyCode = currency.code;
          priceIds = availablePrices[currencyCode];
          priceIdsMonthly = availablePricesMonthly[currencyCode];
        }
      }
    }

    // Only get Startup Registration price
    const startupRegistrationPriceId = priceIds["Startup Registration"];
    const startupRegistrationPriceMonthlyId = priceIdsMonthly["Startup Registration"];

    const stripePriceDataResponse = await getStripePrice(startupRegistrationPriceId);
    const stripePriceData = stripePriceDataResponse.data;

    // const stripePriceDataMonthlyResponse = await getStripePrice(startupRegistrationPriceMonthlyId);
    // const stripePriceDataMonthly = stripePriceDataMonthlyResponse.data;

    priceData["Startup Registration"] = stripePriceData;
    priceDataMonthly["Startup Registration"] = null;

    setState({
      userLocation: response?.data || null,
      currencyCode,
      priceData,
      priceDataMonthly,
      priceIds: { "Startup Registration": startupRegistrationPriceId },
      priceIdsMonthly: { "Startup Registration": startupRegistrationPriceMonthlyId },
      ip: response?.data?.ip,
    });
  };

  const handleAbandonedCart = async () => {
    const res = await createAbandonedCart({
      user_id: userState?.id,
      email: userState?.email,
      action: "Click Register Startup Button",
      location: router.pathname,
      label: "Startup Registration",
    });
  };

  useEffect(() => {
    handleUserIpAddress();
    if (startupToClaim) {
      setStartupName(startupToClaim.name);
      setStartupWebsite(startupToClaim.website);
      onClaimStartup(startupToClaim);
    }
    handleAbandonedCart();
  }, []);

  useEffect(() => {
    const fetchPlaceholderEmails = async () => {
      const website = claimedStartup ? claimedStartup.website : startupWebsite;
      if (website) {
        const result = await getBaseDomainWithList(website);
        if (result.verificationEmails?.length > 0) {
          setPlaceholderEmails(result.verificationEmails.join(', '));
        } else {
          setPlaceholderEmails(`xxxxxx@${result.baseDomain}`);
        }
      }
    };

    fetchPlaceholderEmails();
  }, [claimedStartup, startupWebsite]);

  const handleCheckStartupInfo = async () => {
    setError({
      startupName: !startupName ? "Startup name is required!" : "",
      startupWebsite: !startupWebsite ? "Startup website is required!" : "",
    });

    if (!startupName || !startupWebsite) return;
    if (!isValidDomain(startupWebsite)) {
      return setError({
        startupName: !startupName ? "Startup name is required!" : "",
        startupWebsite:
          "The website is invalid!" +
          (!startupWebsite.includes("http")
            ? " It must have https:// or http:// at the beginning."
            : ""),
      });
    }

    const checkStartupResponse = await checkStartupExist({
      name: startupName,
      website: startupWebsite,
    });

    setStartupExist(checkStartupResponse.data.exists);
    const same = await checkEmailDomainWithList(user.email, startupWebsite);
    setIsSameUserEmailAndWebsite(same);
    if (checkStartupResponse.data && checkStartupResponse.data.exists) {
      setFoundStartup(checkStartupResponse.data.startup);
      setSelectedStep(1);
    } else {
      if (same) {
        if (planSubscription?.is_startup_plan) {
          handleRegisterStartup();
        } else {
          onUpgradeStartup();
        }
        setFoundStartup(null);
      } else {
        setNeedToPutOfficialEmail(true);
      }
    }
  };

  const handleCancel = () => {
    setOpenPurchasePopup(false);
    setError({});
    setStartupName("");
    setStartupWebsite("");
    setSelectedStep(null);
    setStartupExist(false);
    setFoundStartup(null);
  };

  const handleReset = () => {
    setError({});
    setStartupName("");
    setStartupWebsite("");
    setSelectedStep(null);
    setStartupExist(false);
    setFoundStartup(null);
    setSelectedStep(1);
  };

  const onSelectOwner = (value) => {
    setIsOpenStartupOwner(true);
    setSelectedStartupOwner(value);
  };

  const handleRegisterStartup = async () => {
    const payload = {
      name: startupName,
      website: startupWebsite,
      creator_id: user.id,
      owner_id: user.id,
      owner_email: officialEmail,
    };
    setIsLoadingSubmit(true);
    try {
      const response = await createStartupByUser(payload);
      toast.success("Your startup has been successfully created");
      location.href = `/startups/${response.data.data.slug}-${response.data.data.id}`;
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsLoadingSubmit(true);
  };

  const onClaimStartup = async (startup) => {
    const same = await checkEmailDomainWithList(user.email, startup.website);
    setIsSameUserEmailAndWebsite(same);
    if (same) {
      if (planSubscription?.is_startup_plan) {
        handleClaimStartup(startup.id);
      } else {
        setClaimedStartup(startup);
        onUpgradeStartup();
      }
    } else {
      setClaimedStartup(startup);
      setNeedToPutOfficialEmail(true);
    }
  };

  const handleClaimStartup = async (startupId) => {
    const payload = {
      owner_id: user.id,
      claimed: 1,
      owning_type: "claimed",
      owner_email: officialEmail,
    };

    setIsLoadingSubmit(true);
    try {
      const response = await updateStartup(startupId, payload);
      setStartupToClaim(null);
      setClaimedStartup(null);
      toast.success("Your startup has been successfully updated");
      location.href = `/startups/${response.data.data.slug}-${response.data.data.id}`;
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsLoadingSubmit(false);
  };

  const onUpgradeStartup = (type) => {
    // if (!user) {
    //   return setOpenLoginPopup(true);
    // }
    setIsOpen(true);
  };

  return (
    <Dialog open={!!openPurchasePopup} onOpenChange={setOpenPurchasePopup}>
      <DialogContent
        isHidePurchasePopup={isHidePurchasePopup}
        className={`max-w-6xl p-0 bg-white text-background ${
          isHidePurchasePopup ? "hidden" : ""
        }`}
        hideCloseButton
      >
        <ScrollArea className='max-h-[100dvh]'>
          <div className='flex flex-col lg:flex-row'>
            <div className='relative lg:flex-1 lg:m-2.5 lg:mr-0 overflow-hidden bg-gray-100 lg:rounded-md shadow-inner'>
              <button
                onClick={() => setOpenPurchasePopup(false)}
                type='button'
                className='absolute inline-flex md:hidden items-center justify-center rounded-full w-9 h-9 bg-white/[0.15] right-3 top-3'
              >
                <X className='text-xl text-white' />
              </button>
              <div className='p-8 pt-5 text-center text-white lg:pb-10 bg-gradient-to-tr from-orange-1 to-red-1'>
                <span className='inline-flex items-center justify-center mb-3 text-3xl rounded-full w-14 h-14 bg-white/20'>
                  <ArrowFatLineUp />
                </span>
                <p className='text-3xl font-semibold tracking-tight lg:text-5xl font-display'>
                  Upgrade to the Startup Registration Plan!
                </p>
                <p className='mt-3 opacity-80'>
                  To edit and publish your startup profile, so you can start
                  interacting with the MENA Startup and Investor ecosystem,
                  you&apos;ll need to purchase our Startup Registration Plan for{" "}
                  {state ? (
                    <span className='font-semibold'>
                      {state.currencyCode}{" "}
                      {(
                        state.priceData["Startup Registration"].unit_amount / 100
                      ).toLocaleString("en", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                      /year
                    </span>
                  ) : (
                    <span className="animate-pulse">Loading price...</span>
                  )}
                </p>
              </div>
              <div className='justify-center hidden lg:flex lg:-mt-5'>
                <div className='flex items-center p-1 pr-4 bg-white rounded-full shadow-lg gap-x-2'>
                  <div className='overflow-hidden border rounded-full w-9 h-9 border-background/20'>
                    <div className='relative w-9 h-9 aspect-square'>
                      <Image
                        src={allAccessIcon.src}
                        alt=''
                        layout='fill'
                        objectFit='cover'
                      />
                    </div>
                  </div>
                  <div>
                    <p className='text-xs font-medium leading-none'>
                      Startup Registration
                    </p>
                  </div>
                </div>
              </div>
              <div className='grid gap-2 p-5 text-sm lg:grid-cols-2 lg:text-xs lg:p-8 text-background'>
                {startupBenefits.map((item, index) => (
                  <div
                    className='inline-flex items-start space-x-2.5'
                    key={index}
                  >
                    <span className='inline-flex items-center justify-center flex-shrink-0 w-[18px] h-[18px] text-white rounded-full bg-gradient-to-tr from-green-300 to-green-500'>
                      <Check weight='bold' className='text-xs' />
                    </span>
                    <span className='translate-y-px'>{item}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className='p-5 lg:p-7 lg:w-2/5'>
              {/* {!planSubscription?.is_startup_plan && !startupToClaim && (
                <nav aria-label='Progress'>
                  <ol
                    role='list'
                    className='space-y-4 md:flex md:space-x-4 md:space-y-0'
                  >
                    {steps.map((step) => (
                      <li key={step.name} className='md:flex-1'>
                        {selectedStep > step.value ? (
                          <a
                            href={step.href}
                            className='flex flex-col py-2 pl-4 border-l-4 border-red-1 group hover:border-red-1 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4'
                          >
                            <span className='text-[11px] tracking-wide font-medium uppercase text-red-1 group-hover:text-red-1'>
                              {step.id}
                            </span>
                            <span className='text-sm font-medium'>
                              {step.name}
                            </span>
                          </a>
                        ) : selectedStep == step.value ? (
                          <a
                            href={step.href}
                            className='flex flex-col py-2 pl-4 border-l-4 border-red-1 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-2'
                            aria-current='step'
                          >
                            <span className='text-[11px] tracking-wide font-medium uppercase text-red-1'>
                              {step.id}
                            </span>
                            <span className='text-sm font-medium'>
                              {step.name}
                            </span>
                          </a>
                        ) : (
                          <a
                            href={step.href}
                            className='flex flex-col py-2 pl-4 border-l-4 border-gray-200 group hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-2'
                          >
                            <span className='text-[11px] tracking-wide font-medium text-gray-500 uppercase group-hover:text-gray-700'>
                              {step.id}
                            </span>
                            <span className='text-sm font-medium'>
                              {step.name}
                            </span>
                          </a>
                        )}
                      </li>
                    ))}
                  </ol>
                </nav>
              )} */}
              {/* Startup Information */}
              {selectedStep === 1 && !needToPutOfficialEmail && (
                <div>
                  <div className='grid grid-cols-12 gap-4 mt-10'>
                    <div className='flex flex-col col-span-12 gap-1.5'>
                      <Label className='text-background'>Startup Name</Label>
                      <Input
                        className='bg-white ring-offset-white border-background/20'
                        required
                        value={startupName}
                        onInput={(e) => setStartupName(e.target.value)}
                        disabled={foundStartup}
                      />
                      {error && error.startupName && (
                        <ErrorMessage message={error.startupName} />
                      )}
                      {/* <div className='inline-flex items-start space-x-1.5 text-xs text-green-500'>
                      <Check weight='bold' className='text-sm' />
                      <span className='font-medium'>
                        You can proceed with this name
                      </span>
                    </div> */}
                    </div>
                    <div className='flex flex-col col-span-12 gap-1.5'>
                      <Label className='text-background'>Startup Website</Label>
                      <Input
                        className='bg-white ring-offset-white border-background/20'
                        required
                        value={startupWebsite}
                        onInput={(e) => setStartupWebsite(e.target.value)}
                        disabled={foundStartup}
                      />
                      {error && error.startupWebsite && (
                        <ErrorMessage message={error.startupWebsite} />
                      )}
                      {startupExist && !foundStartup?.owner_id && (
                        <div className='inline-flex items-start space-x-1.5 text-xs text-red-600'>
                          <span className='font-medium'>
                            Startup already exists
                          </span>
                        </div>
                      )}
                      {startupExist && foundStartup?.owner_id && (
                        <div className='inline-flex items-start space-x-1.5 text-xs text-red-600'>
                          <X weight='bold' className='text-sm' />
                          <span className='font-medium'>
                            Startup already exists and has been claimed.
                          </span>
                        </div>
                      )}
                    </div>
                    {selectedStep == 1 && foundStartup && (
                      <div className='col-span-12 mt-4'>
                        <p className='font-medium'>
                          We found the startup, is it yours?
                        </p>

                        <div className='divide-y divide-black/10 max-h-[30vh] 2xl:max-h-[40vh] overflow-y-auto -my-2 pt-4'>
                          <div className='gap-4 py-2'>
                            <div className='flex items-start space-x-3'>
                              <div className='relative w-10 overflow-hidden border rounded-lg shadow-sm border-background/10 aspect-square'>
                                <Image
                                  src={foundStartup.logo_url || DummyLogo}
                                  layout={"fill"}
                                  objectFit={"cover"}
                                  alt='Image'
                                />
                              </div>
                              <div>
                                <div className='relative text-sm font-medium text-background'>
                                  <span className='mr-6'>
                                    {foundStartup.name}
                                  </span>
                                  {foundStartup.owner_id && (
                                    <span className='w-full px-2 py-1 text-xs font-semibold text-center uppercase border rounded bg-gradient-to-r from-yellow-500/20 to-orange-500/20 border-white/30'>
                                      Claimed
                                    </span>
                                  )}
                                </div>
                                <a
                                  href={foundStartup.website}
                                  className='block mt-0.5 text-xs font-medium hover:underline opacity-50'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  {foundStartup.website}
                                </a>
                                <div className='flex mt-2 gap-x-4'>
                                  {foundStartup.is_published && (
                                    <a
                                      target='_blank'
                                      href={`/startups/${foundStartup.slug}-${foundStartup.id}`}
                                      rel='noopener noreferrer'
                                      className='px-2 py-1 text-xs font-medium bg-gray-100 border border-gray-200 rounded-md'
                                    >
                                      Review
                                    </a>
                                  )}
                                  {!foundStartup.owner_id && (
                                    <Button
                                      className='text-xs bg-transparent text-background'
                                      size='xs'
                                      onClick={() =>
                                        onClaimStartup(foundStartup)
                                      }
                                      disabled={isLoadingSubmit}
                                    >
                                      Claim
                                    </Button>
                                  )}
                                  {!!foundStartup.owner_id &&
                                    isSameUserEmailAndWebsite && (
                                      <Button
                                        className='text-xs bg-transparent text-background'
                                        size='xs'
                                        onClick={() =>
                                          onSelectOwner(foundStartup)
                                        }
                                      >
                                        Who Claimed This?
                                      </Button>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {selectedStep == 1 && !startupExist ? (
                    <div className='col-span-12'>
                      <div className='flex gap-x-1.5 mt-4'>
                        <Button
                          variant='outline'
                          className='bg-white border-background/20 hover:bg-background/5'
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant='secondary'
                          className='flex-1'
                          onClick={handleCheckStartupInfo}
                          disabled={isLoadingSubmit}
                        >
                          Register Startup
                        </Button>
                      </div>
                      <p className='mt-3 text-xs opacity-50'>
                        We need to check whether or not your startup already
                        exists on Lucidity Insights&apos; platform. If it does,
                        you have the option to immediately claim your startup.
                      </p>
                    </div>
                  ) : (
                    startupExist && (
                      <div className='flex justify-end mt-4 gap-x-4'>
                        <Button
                          variant='outline'
                          className='bg-white border-background/20 hover:bg-background/5'
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                        <Button
                          variant='outline'
                          className='bg-white border-background/20 hover:bg-background/5'
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                      </div>
                    )
                  )}
                </div>
              )}

              {selectedStep === 1 &&
                needToPutOfficialEmail &&
                !isSendingVerifyEmail && (
                  <div className='flex-1 space-y-1.5'>
                    <div className='mt-10'>
                      <h2 className='mb-2 text-sm'>
                        Please enter the official email address for{" "}
                        <span className='font-semibold'>
                          {claimedStartup ? claimedStartup.name : startupName}
                        </span>{" "}
                        as your email address does not match it.
                      </h2>
                      <div className='flex items-end col-span-12 space-x-4'>
                        <div className='flex-1 space-y-1.5'>
                          <Label className='text-xs' htmlFor='official_email'>
                            Official Email{" "}
                            <span className='text-red-700'>*</span>
                          </Label>
                          <Input
                            type='text'
                            id='official_email'
                            className='bg-white ring-offset-white border-background/20'
                            value={officialEmail}
                            name='official_email'
                            onChange={(e) => setOfficialEmail(e.target.value)}
                            placeholder={placeholderEmails}
                            disabled={confirmedOfficialEmail}
                            autoComplete='off'
                          />
                        </div>
                        <div>
                          {!confirmedOfficialEmail ? (
                            <Button
                              variant='secondary'
                              onClick={onSendToken}
                              disabled={
                                !officialEmail ||
                                emailVerificationLoading ||
                                !isValidEmail(officialEmail) ||
                                !isEmailValid
                              }
                            >
                              {emailVerificationLoading
                                ? "Processing..."
                                : "Verify Email"}
                            </Button>
                          ) : (
                            <div className='inline-flex items-center h-12 px-4 space-x-2 text-sm font-medium text-white bg-green-600 rounded-md'>
                              <p>Email is verified</p>
                              <CheckCircle weight='fill' />
                            </div>
                          )}
                        </div>
                      </div>
                      <p className='col-span-12 mt-2 text-xs text-background/60'>
                        To automate the process we need your official email
                        matched with your official website, if not we will
                        verify manually
                      </p>
                    </div>
                  </div>
                )}

              {selectedStep === 1 &&
                needToPutOfficialEmail &&
                isSendingVerifyEmail && (
                  <div className='mt-10'>
                    <InputTokenForAccountRegister
                      {...{
                        isVisible: isSendingVerifyEmail
                          ? true
                          : openRegisterPopup,
                        onVerifyTokenUserRegister,
                        emailVerificationLoading,
                        confirmToken,
                        setConfirmToken,
                        isLoading,
                        sendEmailVerificationLoading,
                        onSendTokenForAccountRegistration: onResendToken,
                      }}
                    />
                  </div>
                )}

              {selectedStep == 2 && (
                <div className='w-full p-3 my-6 rounded bg-slate-200'>
                  {claimedStartup && (
                    <p className='mb-3 text-sm font-semibold'>
                      You are about to claim this startup
                    </p>
                  )}
                  <div className='flex justify-between gap-x-4'>
                    <div className='w-1/2'>
                      <p className='text-xs'>Startup Name</p>
                      <p className='text-sm'>
                        {claimedStartup ? claimedStartup.name : startupName}
                      </p>
                    </div>
                    <div className='w-1/2'>
                      <p className='text-xs'>Startup Website</p>
                      <p className='text-sm'>
                        {claimedStartup
                          ? claimedStartup.website
                          : startupWebsite}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {state && selectedStep == 2 ? (
                <Elements stripe={stripePromise} options={options}>
                  <CardForm
                    theme='light'
                    planType={"Startup Registration"}
                    refreshUserSubscription={refreshUserSubscription}
                    planSubscription={planSubscription}
                    {...{
                      userLocation: state?.userLocation,
                      priceData: state?.priceData,
                      priceDataMonthly: state?.priceDataMonthly,
                      priceIds: state?.priceIds,
                      priceIdsMonthly: state?.priceIdsMonthly,
                      currencyCode: state?.currencyCode,
                      ip: state?.ip,
                    }}
                    nextAction={
                      claimedStartup
                        ? () => handleClaimStartup(claimedStartup.id)
                        : () => handleRegisterStartup()
                    }
                  />
                </Elements>
              ) : (
                !state &&
                selectedStep == 2 && (
                  <div className='flex items-center justify-center'>
                    <p className='text-slate-500 animate-pulse'>
                      Loading Payment
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        </ScrollArea>
      </DialogContent>
      {isOpenStartupOwner && (
        <StartupOwner
          isOpen={isOpenStartupOwner}
          setIsOpen={setIsOpenStartupOwner}
          startup={selectedStartupOwner}
        />
      )}
      <StartupCheckout
        {...{
          userLocation: state?.userLocation,
          priceData: state?.priceData,
          priceDataMonthly: state?.priceDataMonthly,
          priceIds: state?.priceIds,
          priceIdsMonthly: state?.priceIdsMonthly,
          currencyCode: state?.currencyCode,
          ip: state?.ip,
          isOpen,
          setIsOpen,
          createStartup: !startupToClaim,
          createStartupPayload: !startupToClaim
            ? {
                name: startupName,
                website: startupWebsite,
                proposed_by: user.id,
                proposed_owner_email: user.email,
                owning_type: "registered",
              }
            : null,
          claimStartup: !!startupToClaim,
          claimStartupPayload: !!startupToClaim
            ? {
                startup_id: startupToClaim.id,
                proposed_by: user?.id,
                proposed_owner_email: user?.email,
                owning_type: "claimed",
              }
            : null,
        }}
      />
    </Dialog>
  );
};

export default PurchaseStartup;
