import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

import { useGetCurrentUrl } from "@/hooks";
import { useSearchForm } from "@/hooks/search";
import {
  BookmarkSimple,
  CaretDown,
  Heart,
  List,
  SignIn,
  X,
} from "@phosphor-icons/react";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext, useEffect, useRef, useState, useMemo } from "react";
import AppContext from "../../AppContext";
import navLogo from "../../assets/images/nav-logo.svg";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

import { loginPopup, registerPopup } from "@/Atoms";
import {
  ArrowRight,
  Bell,
  BellRinging,
  CalendarCheck,
  Notification,
  Wallet,
} from "@phosphor-icons/react/dist/ssr";
import { useAtom } from "jotai";
import SearchGlobal from "../SearchGlobal";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import { Button } from "../ui/button";
import {
  getNotifications,
  markAsRead,
  markAllAsRead,
} from "@/services/api/Notification";
import { format } from "date-fns";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { mapContentTypeToPath } from "@/utils/sitemap";

function Navbar({ user, logout }) {
  const [openLoginPopup, setOpenLoginPopup] = useAtom(loginPopup);
  const [openRegisterPopup, setOpenRegisterPopup] = useAtom(registerPopup);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleProfile, setToggleProfile] = useState(false);
  const { keyword, setKeyword, doSearch } = useSearchForm();

  const appContext = useContext(AppContext);
  const topics = appContext.state.topic_list || [];
  const { planSubscription, loading, refreshUserSubscription } =
    appContext.state;
  const dropdown = useRef(null);
  const searchInput = useRef(null);
  const router = useRouter();

  const hasSubscription =
    planSubscription && planSubscription.has_active_subscription;
  const isTrialing = planSubscription && planSubscription.status === "trialing";
  const isContentPlan = planSubscription && planSubscription.is_content_plan;
  const isStartupPlan = planSubscription && planSubscription.is_startup_plan;
  const isVCPlan = planSubscription && planSubscription.is_vc_plan;
  const isAngelPlan = planSubscription && planSubscription.is_angel_investor_plan;

  const hasStartup = planSubscription && planSubscription.has_startup;
  const latestStartup = planSubscription && planSubscription.latest_startup;

  const hasVC = planSubscription && planSubscription.has_vc;
  const latestVC = planSubscription && planSubscription.latest_vc;

  const hasAngelVC = planSubscription && planSubscription.has_angel_vc;
  const latestAngelVC = planSubscription && planSubscription.latest_angel_vc;

  const menus = [
    {
      name: "Special Reports",
      url: "/reports",
    },
    {
      name: "Topics",
      children: topics.map((topic) => ({
        name: topic.name,
        url: `/topics/${topic.slug}`,
      })),
      url: "/topics",
    },
    {
      name: "Podcasts",
      url: "/podcasts-masterclasses",
    },
    {
      name: "Opinions",
      url: "/opinions",
    },
    {
      name: "Videos",
      url: "/videos",
    },
    {
      name: "Infobytes",
      url: "/infobytes",
    },
    {
      name: "Spotlights",
      url: "/spotlights",
    },
    {
      name: "Articles",
      url: "/articles",
    },
    {
      name: "News",
      url: "/news",
    },
    {
      name: "Tools & Resources",
      children: [
        {
          name: "Startup Database",
          url: "/startups",
        },
        {
          name: "Investor Database",
          url: "/investors",
        },
        {
          name: "Matchmaking Tool",
          url: "/investors",
        },
        {
          name: "Startup Events",
          url: "/startup-events",
        },
        {
          name: "Newsletter Archive",
          url: "/email-archive",
        },
        {
          name: "Startup Toolbox",
          url: "/toolbox",
        },
      ],
      url: "#",
    },
  ];

  const queryClient = useQueryClient();

  // Remove the manual grouping since data comes pre-grouped
  const [notifications, setNotifications] = useState({});
  const [unreadCount, setUnreadCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const formatNotificationTime = (date) => {
    const now = new Date();
    const notificationDate = new Date(date);
    const diffInMinutes = Math.floor((now - notificationDate) / (1000 * 60));

    if (diffInMinutes < 1) return "Just now";
    if (diffInMinutes < 60) return `${diffInMinutes}m ago`;
    if (diffInMinutes < 1440) return `${Math.floor(diffInMinutes / 60)}h ago`;
    return format(notificationDate, "MMM d, yyyy");
  };

  // Fetch notifications when component mounts and user is logged in
  useEffect(() => {
    const fetchNotifications = async () => {
      if (!user) return;

      try {
        setIsLoading(true);
        const response = await getNotifications();
        if (response?.data?.success) {
          setNotifications(response.data.data);
          setUnreadCount(response.data.unread_count);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotifications();
  }, [user]);

  // Update mark as read handlers
  const handleMarkAsRead = async (id) => {
    try {
      await markAsRead(id);
      // Refetch notifications after marking as read
      const response = await getNotifications();
      if (response?.data?.success) {
        setNotifications(response.data.data);
        setUnreadCount(response.data.unread_count);
      }
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await markAllAsRead();
      // Refetch notifications after marking all as read
      const response = await getNotifications();
      if (response?.data?.success) {
        setNotifications(response.data.data);
        setUnreadCount(response.data.unread_count);
      }
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  // Update the notification icon helper to match API types
  const getNotificationIcon = (type) => {
    switch (type) {
      case "payment_failed":
        return <Wallet className='text-lg' />;
      case "profile_reminder":
        return <Bell className='text-lg' />;
      case "notification":
        return <Bell className='text-lg' />;
      default:
        return <Notification className='text-lg' />;
    }
  };

  // Update the NotificationDropdown component
  const NotificationDropdown = () => (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button
          className='relative inline-flex items-center text-xs font-medium 2xl:text-sm'
          type='button'
        >
          <Bell weight='bold' className='text-xl' />
          {unreadCount > 0 && (
            <span className='absolute inline-flex items-center justify-center w-3.5 h-3.5 text-[9px] font-medium bg-red-500 rounded-full -right-1.5 -top-1.5'>
              {unreadCount}
            </span>
          )}
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='w-[380px] p-0'>
        <div className='flex items-center justify-between px-4 py-3 border-b shadow-md border-white/10'>
          <div className='flex items-center space-x-2 text-base'>
            <Bell weight='bold' className='text-lg opacity-30' />
            <span className='font-medium'>Notifications</span>
          </div>
          {unreadCount > 0 && (
            <Button
              variant='ghost'
              size='sm'
              onClick={handleMarkAllAsRead}
              className='text-xs text-white/60 hover:text-white'
            >
              Mark all as read
            </Button>
          )}
        </div>
        <div className='max-h-[400px] overflow-y-auto'>
          {isLoading ? (
            <div className='flex items-center justify-center p-4'>
              <span className='text-sm text-white/40'>Loading...</span>
            </div>
          ) : Object.keys(notifications).length === 0 ? (
            <div className='flex flex-col items-center justify-center p-8 text-center'>
              <BellRinging
                weight='bold'
                className='mb-2 text-3xl text-white/20'
              />
              <p className='text-sm text-white/40'>No notifications yet</p>
            </div>
          ) : (
            Object.entries(notifications).map(([monthYear, monthNotifications]) => (
              <div key={monthYear}>
                <div className='px-4 py-2.5 text-xs border-b border-white/5 bg-white/5'>
                  <span>{monthYear}</span>
                </div>
                {monthNotifications.map((notification) => (
                  <div
                    key={notification.id}
                    className={`m-1 flex gap-2.5 px-3 py-2 hover:bg-white/5 rounded-md transition-colors duration-300 ${
                      !notification.read_at ? "bg-white/5" : ""
                    }`}
                  >
                    <span className='inline-flex items-center justify-center w-8 h-8 rounded-full bg-white/10'>
                      {notification.type === "payment_failed" ? (
                        <Wallet className='text-lg' />
                      ) : notification.type === "profile_reminder" ? (
                        <Bell className='text-lg' />
                      ) : (
                        notification.content?.thumbnail_url || notification.content?.cover_image_url || notification.content?.background_image_url ? (
                          <Image
                            src={notification.content?.thumbnail_url || notification.content?.cover_image_url || notification.content?.background_image_url}
                            alt={notification.content?.title}
                            width={32}
                            height={32}
                            className='object-cover rounded-md'
                          />
                        ) : (
                          <Notification className='text-lg' />
                        )
                      )}
                    </span>
                    <div className='flex-1'>
                      <p className='flex items-center text-sm'>
                        {!notification.read_at && (
                          <span className='w-1 h-1 mr-2 bg-red-500 rounded-full'></span>
                        )}
                        {notification.data.title}
                      </p>
                      <small className='text-white/40 line-clamp-2'>{notification.type === 'payment_failed' ? notification.data.message : notification.content?.title}</small>
                      <p className='mt-0.5 text-xs text-white/40 mt-1'>
                        {formatNotificationTime(notification.created_at)}
                      </p>
                      {notification.type === "payment_failed" ? (
                        <Link
                          href={notification.data.action_url}
                          target="_blank"
                          onClick={() => {
                            if (!notification.read_at) {
                              handleMarkAsRead(notification.id);
                            }
                          }}
                        >
                          <Button
                            className='h-auto px-2.5 py-1.5 text-xs mt-2'
                            variant='secondary'
                          >
                            View Payment
                          </Button>
                        </Link>
                      ) : notification.type === "profile_reminder" ? (
                        <Link
                          href={notification.data.cta_link}
                          onClick={() => {
                            if (!notification.read_at) {
                              handleMarkAsRead(notification.id);
                            }
                          }}
                        >
                          <Button
                            className='h-auto px-2.5 py-1.5 text-xs mt-2'
                            variant='secondary'
                          >
                            {notification.data.cta}
                          </Button>
                        </Link>
                      ) : (
                        <Link
                          href={`/${mapContentTypeToPath[notification.type]}/${notification.content?.slug || ""}`}
                          target="_blank"
                          onClick={() => {
                            if (!notification.read_at) {
                              handleMarkAsRead(notification.id);
                            }
                          }}
                        >
                          <Button
                            className='h-auto px-2.5 py-1.5 text-xs mt-2'
                            variant='secondary'
                          >
                            {notification.type === "Videos" || notification.type === "Podcast Episodes" ? "Watch " + notification.type : "Read " + notification.type}
                          </Button>
                        </Link>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))
          )}
        </div>
        <div className='p-2 border-t border-white/10'>
          <Button
            variant='secondary'
            className='w-full bg-white/5 hover:bg-white/10'
            onClick={() => router.push("/account/notifications")}
          >
            <span className='flex items-center justify-center gap-2'>
              See All Notifications
              <ArrowRight className='text-base' />
            </span>
          </Button>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = document.documentElement.scrollTop;
      const isVisible = prevScrollPos > currentScrollPos;

      setPrevScrollPos(currentScrollPos);
      setVisible(isVisible);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  const currentUrl = useGetCurrentUrl();

  useEffect(() => {
    setToggleMenu(false);
    setToggleProfile(false);
  }, [currentUrl]);

  useEffect(() => {
    // only add the event listener when the dropdown is opened
    if (!toggleProfile) return;
    function handleClick(event) {
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        setToggleProfile(false);
      }
    }
    window.addEventListener("click", handleClick);
    // clean up
    return () => window.removeEventListener("click", handleClick);
  }, [toggleProfile]);

  function setFocus(event) {
    event.preventDefault();
    searchInput.current.focus();
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Command + / (MacOS) or Ctrl + / (Windows) key combination
      if ((event.metaKey || event.ctrlKey) && event.key === "/") {
        setIsSearchOpen(!isSearchOpen);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isSearchOpen]);

  const onSearch = (e) => {
    if (router.pathname == "/search/results") {
      setIsSearchOpen(false);
      doSearch(e);
    } else {
      setIsSearchOpen(false);
      doSearch(e, setIsSearchOpen);
    }
  };

  function DropdownProfile(user) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger className='ml-auto mr-3'>
          <Avatar className='w-8 h-8 ring-1 ring-offset-1 ring-offset-background ring-orange-1/30'>
            <AvatarImage src={user.profile_image_url} />
            <AvatarFallback className='text-xs font-medium uppercase'>
              {user.first_name ? user.first_name[0] : user.name[0]}
              {user.last_name ? user.last_name[0] : ""}
            </AvatarFallback>
          </Avatar>
        </DropdownMenuTrigger>
        <DropdownMenuContent collisionPadding={8}>
          <DropdownMenuLabel className='flex space-x-2.5'>
            <Avatar className='w-9 h-9'>
              <AvatarImage src={user.profile_image_url} />
              <AvatarFallback className='text-xs font-medium uppercase'>
                {user.first_name ? user.first_name[0] : user.name[0]}
                {user.last_name ? user.last_name[0] : ""}
              </AvatarFallback>
            </Avatar>
            <div className='flex flex-col'>
              <span className='text-sm'>
                {user.first_name} {user.last_name}
              </span>
              <span className='text-xs font-normal text-white/40'>
                {user.email}
              </span>
            </div>
          </DropdownMenuLabel>
          <p className='px-2 mt-3 mb-1 text-xs opacity-50'>My Profiles</p>
          {hasStartup && (
            <DropdownMenuItem>
              <Link legacyBehavior href={"/account/startup"}>
                <a className='w-full'>My Profile</a>
              </Link>
            </DropdownMenuItem>
          )}
          {hasVC && (
            <DropdownMenuItem>
              <Link legacyBehavior href={"/account/vc"}>
                <a className='w-full'>My Profile</a>
              </Link>
            </DropdownMenuItem>
          )}
          {hasAngelVC && (
            <DropdownMenuItem>
              <Link legacyBehavior href={"/account/angel"}>
                <a className='w-full'>My Profile</a>
              </Link>
            </DropdownMenuItem>
          )}
          <p className='px-2 mt-3 mb-1 text-xs opacity-50'>Account</p>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/profile"}>
              <a className='w-full'>Profile</a>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/password"}>
              <a className='w-full'>Password</a>
            </Link>
          </DropdownMenuItem>
          <p className='px-2 mt-3 mb-1 text-xs opacity-50'>Content</p>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/preferences"}>
              <a className='w-full'>Preferences</a>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/bookmarks"}>
              <a className='w-full'>Bookmarks</a>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/watchlist"}>
              <a className='w-full'>Watchlist</a>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/report-history"}>
              <a className='w-full'>Report History</a>
            </Link>
          </DropdownMenuItem>
          <p className='px-2 mt-3 mb-1 text-xs opacity-50'>Subscription</p>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/plan"}>
              <a className='w-full'>My Plan</a>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/payment"}>
              <a className='w-full'>Payment</a>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link legacyBehavior href={"/account/billing"}>
              <a className='w-full'>Billing</a>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onSelect={logout}>Log out</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }

  return (
    <div className='sticky top-0 z-50'>
      <div className='flex items-center justify-center gap-3 text-sm text-white shadow-md top-28 h-9 bg-gradient-to-l from-red-500 to-purple-600'>
        <div className='flex items-center gap-1.5 font-medium'>
          <CalendarCheck weight='bold' />
          <span>Book a free demo with us</span>
        </div>
        <Link
          href='https://calendly.com/lucy-viqk/free-onboarding-demo'
          target='_blank'
          className='inline-flex items-center gap-1.5 px-2.5 py-1.5 text-xs font-medium text-white bg-black rounded-md shadow-md'
        >
          Book Now
          <ArrowRight weight='bold' />
        </Link>
      </div>
      <div
        className={`bg-background backdrop-blur-lg transition-transform duration-500`}
      >
        <div className='hidden h-16 border-b bg-background lg:block border-white/5'>
          <div className='container flex items-center h-full pt-1 my-auto space-x-5 lg:pt-0'>
            <div className='items-center hidden lg:inline-flex'>
              <Link legacyBehavior href={"/"} passHref>
                <a className='inline-block relative w-auto h-8 2xl:h-10 aspect-[189/48]'>
                  <Image
                    src={navLogo.src}
                    alt='Lucidity Insights'
                    layout='fill'
                    objectFit='cover'
                  />
                </a>
              </Link>
            </div>
            <div className='flex-1'>
              <SearchGlobal />
            </div>
            {user ? (
              <div className='flex items-center space-x-5'>
                {hasStartup && (
                  <Link legacyBehavior href={latestStartup ? `/startups/${latestStartup.slug + '-' + latestStartup.id}` : "/account/startup"} passHref>
                    <a className='text-sm font-medium duration-300 whitespace-nowrap hover:opacity-70'>
                      My Startup
                    </a>
                  </Link>
                )}
                {hasVC && (
                  <Link legacyBehavior href={latestVC ? `/investors/${latestVC.slug + '-' + latestVC.id}` : "/account/vc"} passHref>
                    <a className='text-sm font-medium duration-300 whitespace-nowrap hover:opacity-70'>
                      My VC
                    </a>
                  </Link>
                )}
                {hasAngelVC && (
                  <Link legacyBehavior href={latestAngelVC ? `/angel-investors/${latestAngelVC.slug + '-' + latestAngelVC.id}` : "/account/angel"} passHref>
                    <a className='text-sm font-medium duration-300 whitespace-nowrap hover:opacity-70'>
                      My Angel
                    </a>
                  </Link>
                )}
                <div className='flex items-center gap-x-3'>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <button
                          type='button'
                          onClick={() => router.push("/account/watchlist")}
                          className='inline-block text-sm font-medium duration-300 whitespace-nowrap hover:opacity-70'
                        >
                          <Heart weight='bold' className='text-xl' />
                        </button>
                      </TooltipTrigger>
                      <TooltipContent>Watchlist</TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <button
                          type='button'
                          onClick={() => router.push("/account/bookmarks")}
                          className='inline-block text-sm font-medium duration-300 whitespace-nowrap hover:opacity-70'
                        >
                          <BookmarkSimple weight='bold' className='text-xl' />
                        </button>
                      </TooltipTrigger>
                      <TooltipContent>Bookmarks</TooltipContent>
                    </Tooltip>
                  </TooltipProvider>

                  <NotificationDropdown />
                </div>
                <div className=''>
                  <DropdownProfile {...user} />
                </div>
              </div>
            ) : (
              <div className='flex items-center justify-center'>
                <button
                  type='button'
                  onClick={() => setOpenRegisterPopup(true)}
                >
                  <a className='hidden pr-3 mr-3 text-sm text-white border-r lg:block border-white/10'>
                    <span>Register</span>
                  </a>
                </button>
                <button
                  type='button'
                  onClick={() => setOpenLoginPopup(true)}
                  className='inline-flex items-center text-sm font-medium'
                >
                  <SignIn weight='bold' className='mr-1.5 lg:opacity-60' />
                  <span>Login</span>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className='w-full h-12 text-white duration-300 ease-out bg-[#161617]/80 lg:border-b lg:border-white/5'>
          <div className='container flex items-center w-full h-full lg:justify-between'>
            <div className='flex items-center flex-1 space-x-8 lg:space-x-0'>
              <div className='inline-flex items-center lg:hidden'>
                <Link legacyBehavior href={"/"} passHref>
                  <a className='inline-block relative w-auto h-10 2xl:h-12 aspect-[189/48]'>
                    <Image
                      src={navLogo.src}
                      alt='Lucidity Insights'
                      layout='fill'
                      objectFit='cover'
                    />
                  </a>
                </Link>
              </div>
              <div className='flex-col flex-wrap items-center hidden h-full gap-3 pt-4 my-auto lg:flex lg:pt-0 2xl:gap-6 lg:flex-nowrap lg:flex-row gap-y-1'>
                {menus.map((menu, index) =>
                  !menu.children ? (
                    <Link legacyBehavior href={menu.url} passHref key={index}>
                      <a className='text-xs 2xl:text-sm font-medium flex items-center px-4 py-2.5 lg:p-0 rounded-lg bg-white/5 lg:bg-transparent w-full lg:w-auto whitespace-nowrap'>
                        {menu.name}
                      </a>
                    </Link>
                  ) : (
                    <DropdownMenu key={index}>
                      <DropdownMenuTrigger className='inline-flex items-center text-xs font-medium 2xl:text-sm'>
                        {menu.name}
                        <CaretDown weight='bold' className='ml-1' />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        {menu.children.map((child, index) => (
                          <DropdownMenuItem key={index}>
                            <Link legacyBehavior href={child.url} passHref>
                              <a className='w-full'>{child.name}</a>
                            </Link>
                          </DropdownMenuItem>
                        ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  )
                )}
              </div>
            </div>
            <div className='hidden lg:flex items-center justify-end space-x-1 2xl:space-x-1.5'>
              <Link legacyBehavior href={"/startups"} passHref>
                <a className='text-xs 2xl:text-sm font-medium flex items-center px-2.5 py-2.5 2xl:px-3 lg:py-1 rounded lg:bg-gradient-to-r from-orange-500 to-yellow-600 w-full lg:w-auto whitespace-nowrap'>
                  Startup Database
                </a>
              </Link>
              <Link legacyBehavior href={"/investors"} passHref>
                <a className='text-xs 2xl:text-sm font-medium flex items-center px-2.5 py-2.5 2xl:px-3 lg:py-1 rounded lg:bg-gradient-to-r from-purple-500 to-red-500 w-full lg:w-auto whitespace-nowrap -ml-4'>
                  Investor Database
                </a>
              </Link>
              <Link legacyBehavior href={"/pricing"} passHref>
                <a className='whitespace-nowrap font-medium text-background w-full lg:w-auto px-2.5 py-2.5 2xl:px-3 lg:py-1 rounded bg-white text-xs 2xl:text-sm justify-center inline-flex space-x-1.5 items-center'>
                  ⚡️ Plans
                </a>
              </Link>
            </div>
            <div className='flex items-center ml-auto'>
              {!user && (
                <div onClick={() => setOpenRegisterPopup(true)}>
                  <a className='mr-3 text-sm font-medium lg:hidden whitespace-nowrap'>
                    Register
                  </a>
                </div>
              )}
              {!user ? (
                <div className='flex items-center mr-2 space-x-2.5 lg:hidden ml-4'>
                  <div className='flex items-center justify-center ml-auto'>
                    <div onClick={() => setOpenLoginPopup(true)}>
                      <a className='inline-flex items-center justify-center w-8 h-8 text-lg rounded-md bg-white/5'>
                        <SignIn
                          color='#ffffff'
                          weight='bold'
                          className='opacity-60'
                        />
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='flex items-center ml-auto mr-4 space-x-4 lg:hidden'>
                  <button
                    type='button'
                    onClick={() => router.push("/account/watchlist")}
                    className='inline-block text-sm font-medium duration-300 whitespace-nowrap hover:opacity-70'
                  >
                    <Heart weight='bold' className='text-2xl' />
                  </button>
                  <button
                    type='button'
                    onClick={() => router.push("/account/bookmarks")}
                    className='inline-block text-sm font-medium duration-300 whitespace-nowrap hover:opacity-70'
                  >
                    <BookmarkSimple weight='bold' className='text-2xl' />
                  </button>
                  <DropdownProfile {...user} />
                </div>
              )}
              <button
                onClick={toggleNav}
                className='lg:hidden'
                aria-label='Toggle Navigation Menu'
              >
                {toggleMenu ? (
                  <X size={28} color='#ffffff' weight='bold' />
                ) : (
                  <List size={28} color='#ffffff' weight='bold' />
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        {toggleMenu && (
          <div className='h-[calc(100vh-4rem)] overflow-y-auto px-4 pt-3 pb-4 text-white lg:hidden'>
            <div className='mb-3'>
              <SearchGlobal />
            </div>
            <div className='flex flex-col justify-between space-y-1.5'>
              {menus.map((menu, index) =>
                !menu.children ? (
                  <Link legacyBehavior href={menu.url} passHref key={index}>
                    <a className='flex items-center px-4 py-2.5 lg:p-0 rounded-lg bg-white/5 lg:bg-transparent w-full lg:w-auto whitespace-nowrap'>
                      <span className='text-sm font-medium lg:m-0'>
                        {menu.name}
                      </span>
                    </a>
                  </Link>
                ) : (
                  <Accordion
                    type='single'
                    collapsible
                    className='w-full'
                    key={index}
                  >
                    <AccordionItem value='topics' className='border-0'>
                      <AccordionTrigger className='flex !no-underline items-center px-4 py-2.5 lg:p-0 !text-sm rounded-lg bg-white/5 lg:bg-transparent w-full lg:w-auto whitespace-nowrap'>
                        {menu.name}
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className='pl-3 mt-2 space-y-1 border-l border-white/10'>
                          {menu.children.map((child, index) => (
                            <Link
                              legacyBehavior
                              href={child.url}
                              passHref
                              key={index}
                            >
                              <a className='flex items-center w-full px-4 py-2 rounded-lg lg:p-0 bg-white/5 lg:bg-transparent lg:w-auto whitespace-nowrap'>
                                <span className='text-xs font-medium lg:m-0'>
                                  {child.name}
                                </span>
                              </a>
                            </Link>
                          ))}
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                )
              )}
              <Link legacyBehavior href={"/startups"} passHref>
                <a className='flex items-center px-4 py-2.5 lg:px-2 lg:py-1 rounded bg-gradient-to-r from-orange-500 to-yellow-500 w-full lg:w-auto whitespace-nowrap'>
                  <span className='text-sm font-medium lg:m-0'>
                    Startup Database
                  </span>
                </a>
              </Link>
              <Link legacyBehavior href={"/investors"} passHref>
                <a className='flex items-center px-4 py-2.5 lg:px-2 lg:py-1 rounded bg-gradient-to-r from-purple-500 to-red-500 w-full lg:w-auto whitespace-nowrap'>
                  <span className='text-sm font-medium lg:m-0'>
                    Investor Database
                  </span>
                </a>
              </Link>
              <Link legacyBehavior href={"/pricing"} passHref>
                <a className='flex items-center px-4 py-2.5 lg:px-2 lg:py-1 rounded text-background bg-white w-full lg:w-auto whitespace-nowrap'>
                  <span className='text-sm font-medium lg:m-0'>Plans</span>
                </a>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
